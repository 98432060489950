export default {
	
	
	//urlDev:'http://127.0.0.1:8080/api',//测试地址
	urlDev:'https://www.shop-hz.top/api',//线上地址
	//urlDev:'http://118.178.235.12:8080/api',//线上地址
	
	
	
	urlPro:'https://www.shop-hz.top/api',//线上地址
	qiniuUrl:'https://shop-hz.top/',//七牛云浏览图片的域名地址
	qiniuUploadUrl:'https://up-cn-east-2.qiniup.com', //上传到的服务器地址
	
	
	
	
	
	 
	
	
	
	 datetimePickeOption: {
	    shortcuts: [
	      {
	        text: '1 week',
	        value () {
	          const end = new Date()
	          const start = new Date()
	          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
	          return [start, end]
	        }
	      },
	      {
	        text: '1 month',
	        value () {
	          const end = new Date()
	          const start = new Date()
	          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
	          return [start, end]
	        }
	      },
	      {
	        text: '3 months',
	        value () {
	          const end = new Date()
	          const start = new Date()
	          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
	          return [start, end]
	        }
	      }
	    ]
	  },
	  
	  
	  
	  
};