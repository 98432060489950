import Vue from "vue";
import Vuex from "vuex";
import axios from 'axios';


Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		oss_upload_sign: {}, //oss服务器上传文件时的对象，里面有上传到的文件服务器地址和令牌和令牌过期时间和key等...
	},
	//编写同步方法
	mutations: {
		//创建一个修改state中属性值的方法
		SET_OSS_UPLOAD_SIGN: (state, param) => { //添加或修改上传参数对象的值
			state.oss_upload_sign = param
		}
	},
	//编写异步方法
	actions: {
		//调用格式 在xx.vue中编写 let uploadParamObj =  await this.$store.dispatch('getOSSUploadSign');
		 //检查是否有上传文件参数对象并且上传令牌有没有过期，过期后发送异步请求获取上传文件参数对象并赋值
		getOSSUploadSign({
			commit,
			state
		}) {
			//上传文件令牌没有过期就返回上传文件参数对象
			if (state.oss_upload_sign && new Date(state.oss_upload_sign.expire) > new Date()) {
				console.log('上传文件令牌没有过期了')
				return state.oss_upload_sign
			}

			console.log('上传文件令牌过期了')
			//上传文件令牌已过期就重新获取
			return new Promise((resolve, reject) => {

				console.log('准备发请求')
				axios({
					method: 'POST',
					url: '/getQiNiuToken',
					data: {
						
					},
					header: {

					},
				}).then(res => {

					let sign = res.data.data;
					console.log('获取上传文件令牌成功了')
					commit('SET_OSS_UPLOAD_SIGN', sign); //异步请求成功后触发执行mutations中的SET_OSS_UPLOAD_SIGN方法修改state中的属性值
					resolve(sign) //执行成功回调
				}).catch(error => {
					reject(error) //执行失败回调
				})
			})
		}
	},
	modules: {}
});
