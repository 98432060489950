<template>
	<div id="loayoutId">

		<el-container :style="{'height':height}">
			<!-- 侧边栏start -->
			<el-aside :width="isCollapse?'70px':'200px'" style="transition: all 0.5s;background-color:#191A23;">


				<!-- /index -->
				<el-menu :default-active="$route.path" router :collapse="isCollapse" style=" height: 100%;" class="el-menu-vertical-demo" background-color="#001529" text-color="#B3B7BA" active-text-color="#fff">
					<!-- 商标start -->
					<div style="text-align: center;margin: 10px 0;background-color: #191A23;">
						<img style="width: 50px; height: 50px;background-color: #191A23;" :src="icon"></img>
					</div>
					<!-- 商标end -->

					<!-- 侧边导航栏项start 循环遍历出路由地址项  $router.options.routes代表返回路由配置的路由数组  item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 -->
					<!-- 根据$router.options.routes循环遍历出路由配置页的路由数组  根据item.meta.menuShow判断是否把当前路由项显示在侧边导航栏上 和 根据 jueSheList中是否有当前用户的role角色标识 -->
					<template v-for="(item , index) in $router.options.routes">
						<template v-if="item.meta.menuShow">
							<!-- 当前路由有子路由的情况start -->
							<el-submenu :index="item.path" v-if="item.children && item.children.length > 1 && item.meta.jueSheList.indexOf(role)!=-1">

								<template slot="title">
									<!-- 当前父路由图标和标题名 -->
									<i :class="item.meta.icon"></i>
									<span slot="title">{{item.meta.menuName}}</span>
								</template>

								<!-- 遍历子路由start -->

								<template v-for="(itemChild , index) in item.children">

									<el-menu-item v-if="itemChild.meta.menuShow && itemChild.meta.jueSheList.indexOf(role)!=-1" :index="itemChild.path">
										<!-- 当前子路由图标和标题名 -->
										<i :class="itemChild.meta.icon"></i>
										<span>{{itemChild.meta.menuName}}</span>
									</el-menu-item>
								</template>
								<!-- 遍历子路由end -->
							</el-submenu>
							<!-- 当前路由有子路由的情况end -->

							<!-- 当前路由没有子路由的情况start -->
							<el-menu-item v-else-if="item.meta.jueSheList.indexOf(role)!=-1" :index="item.children[0].path">
								<!-- 当前路由图标和标题名 -->
								<i :class="item.children[0].meta.icon"></i>
								<span slot="title">{{item.children[0].meta.menuName}}</span>
							</el-menu-item>
							<!-- 当前路由没有子路由的情况end -->

						</template>
					</template>
					<!-- 侧边导航栏项end -->

				</el-menu>


			</el-aside>
			<!-- 侧边栏end -->
			<el-container>
				<!-- 头部start -->
				<el-header style="display: flex;align-items: center;justify-content: space-between;">
					<div style="display: flex;align-items: center;">
						<!-- 折叠按钮start -->
						<div @click="isOpenAside" style="margin-right: 30px;">
							<i v-if="!isCollapse" class="el-icon-s-fold" style="color: #191A23;font-size: 25px;cursor: pointer;"></i>
							<i v-else class="el-icon-s-unfold" style="color: #191A23;font-size: 25px;cursor: pointer;"></i>
						</div>
						<!-- 折叠按钮end -->
						<el-breadcrumb separator-class="el-icon-arrow-right">

							<!-- 动态遍历出面包屑start -->
							<el-breadcrumb-item v-for="(item, index) in breadList" :key="index"><i :class="item.meta.icon" style="margin-right: 3px;"></i>{{item.meta.menuName}}</el-breadcrumb-item>
							<!-- 动态遍历出面包屑end -->

						</el-breadcrumb>
					</div>

					<!-- 用户start -->
					<div style="margin-right: 50px;display: flex;align-items: center;">

						<el-image style="width: 40px; height: 40px;margin-right: 10px;" :src="avatar"></el-image>
						<el-dropdown @command="loginOut">
							<span class="el-dropdown-link" style="cursor: pointer;">
								{{nickname}}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command='loginOut'>退出</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<!-- 用户end -->



				</el-header>
				<!-- 头部end -->

				<!-- 主体start -->
				<el-main>
					<transition name="breadcrumb">
						<router-view></router-view>
					</transition>
				</el-main>
				<!-- 主体end -->
			</el-container>
		</el-container>


	</div>
</template>

<script>
	export default {
		name: "app",
		components: {

		},
		data() {
			return {
				breadList: [], //面包屑列表数据  {meta:{menuName:'首页',icon:'el-icon-s-home'} }
				height: window.innerHeight + 'px',
				icon: require('@/assets/logo.png'),
				isCollapse: false, //是否折叠侧边栏

				role: '', //当前角色标识名称

				avatar: '',
				nickname:'',
				avatarDef: require('@/assets/userImgDef.png'),
			};
		},
		// 监听属性
		watch: {
			// 监听路由变化重新获取面包屑列表数据,currentRouterItemObj代表当前路由地址对应的路由配置项对象
			$route(currentRouterItemObj) {
				// 调用获取路由数组方法
				this.getBreadList(currentRouterItemObj);
			}
		},
		mounted() {
			this.role = localStorage.getItem('role')?localStorage.getItem('role').replace(/3alxi2fdksjfkljwijljsk128ldfln324kodnvjdsl32432jfiwefjls32432idaxpoife983iw2/g,''):''; //获取当前用户角色权限标识名称

			let avatar = localStorage.getItem('avatar');
			let nickname = localStorage.getItem('nickname');
			this.nickname = nickname ? nickname : '用户名';
			console.log('avatar',avatar)
			this.avatar = avatar!="" ? avatar : this.avatarDef;
		},
		methods: {
			//退出
			loginOut(command) {
				localStorage.removeItem('token'); //删除token
				localStorage.removeItem('role'); //删除用户角色标识
				this.$router.push({
					path: '/login'
				})
			},
			//展开和折叠侧边栏
			isOpenAside() {
				this.isCollapse = !this.isCollapse;
			},
			//获取面包屑列表数据
			getBreadList(currentRouterItemObj) {

				// currentRouterItemObj.matched;代表返回当前路由地址配置项对象对应的路由地址对应的所有路由配置项对象
				if (currentRouterItemObj.matched) {
					let menuNameList = currentRouterItemObj.matched.filter(item => item.meta && item.meta.menuName); //返回当前路由地址配置项对象对应的路由地址对应的所有路由配置项对象中属性menuName的值
					// 拿到过滤好的路由v-for遍历出来
					this.breadList = menuNameList;
					console.log('this.breadList', this.breadList)
				}
			},

		}



	};
</script>


<style scoped="scoped">
	>>>.el-menu {
		border: none !important;

	}









	>>>.el-main {
		padding: 0 !important;
		border-top: 1px solid #F1F1F1 !important;
		background-color: #f8f8f8 !important;
	}

	/*侧边栏选中项*/
	>>>.el-menu-item.is-active {
		/* background-color: rgb(64,158,255,0.7) !important; */
		
		background-color: #119744 !important;
	}

	/*鼠标移入侧边栏项*/
	>>>.el-menu-item:hover {
		/* background-color: #409EFF !important; */
		color: #fff !important;
		background-color: rgb(17,151,68,0.6) !important;
	}
	>>>.el-menu-item:hover i {
		/* background-color: #409EFF !important; */
		color: #fff !important;
		
	}
	
	

	>>>.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;

	}

	>>>.el-menu--collapse {
		width: 70px !important;

	}




	.breadcrumb-enter-active,
	.breadcrumb-leave-active {
		transition: all .5s;
	}

	.breadcrumb-enter,
	.breadcrumb-leave-active {
		opacity: 0;
		transform: translateX(20px);
	}

	.breadcrumb-move {
		transition: all .5s;
	}

	.breadcrumb-leave-active {
		position: absolute;
	}
	
	/* 鼠标移入折叠的项start */
	#loayoutId >>>.el-submenu__title:hover{
		color: #fff !important;
		background-color: rgb(17,151,68,0.6) !important;
	}
	#loayoutId >>>.el-submenu__title:hover i {
		
		color: #fff !important;
		
	}
	/* 鼠标移入折叠的项end */
	
</style>
