import axios from 'axios';
import router from '../router';
import config from './config.js';


if (process.env.NODE_ENV == 'development') { //开发环境
	axios.defaults.baseURL = config.urlDev;
} else if (process.env.NODE_ENV == 'production') { //线上环境
	axios.defaults.baseURL = config.urlPro;
}

axios.defaults.timeout = 10000; //请求超时时间
//axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';//代表在任意方式的http请求时添加指定的http请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'; //代表在post请求时添加指定的http请求头   并且如果后端没有用json代码获取jons参数就修改为默认的  'application/x-www-form-urlencoded;charset=UTF-8'然后在post方法上第二参数转换为JSON.stringify(params) 转换为字符串

//发送的http请求之前的拦截器适合做发送请求之前携带token
axios.interceptors.request.use(
	config => { //config 代表封装了http请求信息
		

		// 每次发送请求之前判断是否存在token        
		// 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
		const token = localStorage.getItem("token");
		token && (config.headers.token = token);
		console.log('请求参数和请求头为', config);
		return config;
	},
	error => {
		return Promise.error(error);
	})

// http响应之后的拦截器
axios.interceptors.response.use(
	response => {
		
		return Promise.resolve(response);
	},

	error => {
		return Promise.reject(error);
	}
);








//ajax请求
export function ajax(url, method, params, header) {
	return new Promise((resolve, reject) => {
		
		
		let axiosParam = {};
		
		if(method=='post'||method=='POST'||method=='put'||method=='PUT'){
			axiosParam = {
				method,
				url,
				data:params,//data代表把请求参数添加到请求体中
				header
			};
		}else{
			axiosParam = {
				method,
				url,
				params,//params代表把请求参数添加到url请求地址后面
				header
			};
		}

		axios(axiosParam).then((res1) => {
			console.log('window.ElementUI变量可以使用了', window.ElementUI)
			console.log('res1===>', res1)
			// 如果返回的状态为成功，说明接口请求成功，可以正常拿到数据
			if (res1.data.message == 'success') {

				//请求成功
				console.log('请求成功')
				resolve(res1); //执行成功的回调


			} else { //接口响应的不是成功的状态那么就显示后端提示的错误信息

				if (res1.data.message.indexOf('登录') != -1) { //登录过期了重新登录
					console.log('跳转到登录')
					
					localStorage.removeItem('token'); //删除token
					localStorage.removeItem('role'); //删除用户角色标识
					router.push({
						path: '/login'
					});
					

				} else {
					reject(res1);
					setTimeout(()=>{
						document.getElementById("pageLoading").style.display="none";//隐藏加载中的图标
					},2000)
					
					window.ElementUI.Message.error(res1.data.message); //弹出接口返回的错误提示信息
				}

			}
		}).catch((err) => {
			console.log('err', err)
			 
			 setTimeout(()=>{
			 	document.getElementById("pageLoading").style.display="none";//隐藏加载中的图标
			 },2000)
		
			window.ElementUI.Message.error('出错了'); //弹出接口返回的错误提示信息
		})

	});
}





//在其他xx.vue 中使用的格式为;
//import { ajax } from '../ajax.js'
// 在方法内编写:
// async test(){
			
// 				const res1 = await ajax('/hq/goods/list','GET',{},{})
			
// 			},

////vue中封装axios结束
