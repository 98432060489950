import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout/index.vue";
import my404 from "../views/my404.vue";
import store from '@/store'
import config from '../util/config.js';



let url = '';
if (process.env.NODE_ENV == 'development') { //开发环境
	url = config.urlDev;
} else if (process.env.NODE_ENV == 'production') { //线上环境
	url = config.urlPro;
}



Vue.use(VueRouter);



const routes = [
	
		//登录页start
		{
		    path: '/login',// 登录页
		    name: 'login',
		    component: () =>import("../views/login/login.vue"),//懒加载组件，可以提高效率速度，和减少项目体积大小
		    meta: {
				icon:'el-icon-menu',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
		        menuShow: false,// 是否在侧边导航栏中显示当前路由  在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				menuName:'登录', // 侧边导航栏中显示的导航栏项路由名称    在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				jueSheList:['*'],//允许访问当前配置的路由地址的角色权限标识名称数组,*号代表所有用户都可以访问
			}
		},
		//登录页end
		
		
		
		
		//斜杠页start
		{
	        path: '/',
			redirect: '/index',//默认重定向到/home路由地址
	        meta: {
				icon:'el-icon-menu',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
	            menuShow: false,
				jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
	        }
	    },
		//斜杠页end
		
		
		//首页start
	    {
	        path: '/',
	        name: 'layout',
	        component: Layout,//代表当路由为 /子路由index 时同时显示 /子路由index 路由对应的子路由的页面内容和 / 对应的当前属性component指定的Layout组件对应的页面布局
	        meta: {
				icon:'el-icon-menu',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
	            menuShow: true,// 是否在侧边导航栏中显示当前路由  在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				menuName:'', // 侧边导航栏中显示的导航栏项路由名称 并且名称为空就不会在面包屑上显示了    在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
			},
			
			children: [//子路由格式
			    {
			        path: '/index',//代表匹配路由地址为 /index
			        name: 'Index',
			        component: () =>import("../views/index/index.vue"),//懒加载组件，可以提高效率速度，和减少项目体积大小,,
			        meta: {
						icon:'el-icon-data-line',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
			            menuShow: true,
			            menuName: '首页',
						jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
			        }
			    }
				
			]
			
	    },
		//首页end
		
	
		
		
		
		
		
		//商品列表页start
		
		{
		    path: '/',
		    name: 'layout',
		    component: Layout,//代表当路由为 /子路由index 时同时显示 /子路由index 路由对应的子路由的页面内容和 / 对应的当前属性component指定的Layout组件对应的页面布局
		    meta: {
				icon:'el-icon-shopping-bag-2',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
		        menuShow: true,// 是否在侧边导航栏中显示当前路由  在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				menuName:'商品', // 侧边导航栏中显示的导航栏项路由名称 并且名称为空就不会在面包屑上显示了    在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
			},
			
			children: [//子路由格式
			    {
			        path: '/goodsList',//代表匹配路由地址为 /index
			        name: 'GoodsList',
			        component: () =>import("../views/goodsList/goodsList.vue"),//懒加载组件，可以提高效率速度，和减少项目体积大小,,
			        meta: {
						icon:'el-icon-shopping-bag-2',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
			            menuShow: true,
			            menuName: '商品',
						jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
			        }
			    },
				
				
			]
			
		},
		//商品列表页end
		

		
		//商品分类列表页start
		{
		    path: '/',
		    name: 'layout',
		    component: Layout,//代表当路由为 /子路由index 时同时显示 /子路由index 路由对应的子路由的页面内容和 / 对应的当前属性component指定的Layout组件对应的页面布局
		    meta: {
				icon:'el-icon-files',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
		        menuShow: true,// 是否在侧边导航栏中显示当前路由  在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				menuName:'分类', // 侧边导航栏中显示的导航栏项路由名称 并且名称为空就不会在面包屑上显示了    在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
			},
			
			children: [//子路由格式
			    {
			        path: '/classify',//代表匹配路由地址为 /index
			        name: 'classify',
			        component: () =>import("../views/classify/classify.vue"),//懒加载组件，可以提高效率速度，和减少项目体积大小,,
			        meta: {
						icon:'el-icon-files',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
			            menuShow: true,
			            menuName: '分类',
						jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
			        }
			    },
				
				
			]
			
		},
		//商品分类列表页end
		
		
		
		//用户页start
		
		{
		    path: '/',
		    name: 'layout',
		    component: Layout,//代表当路由为 /子路由index 时同时显示 /子路由index 路由对应的子路由的页面内容和 / 对应的当前属性component指定的Layout组件对应的页面布局
		    meta: {
				icon:'el-icon-user',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
		        menuShow: true,// 是否在侧边导航栏中显示当前路由  在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				menuName:'用户', // 侧边导航栏中显示的导航栏项路由名称 并且名称为空就不会在面包屑上显示了    在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
			},
			
			children: [//子路由格式
			    {
			        path: '/userList',//代表匹配路由地址为 /index
			        name: 'userList',
			        component: () =>import("../views/userList/userList.vue"),//懒加载组件，可以提高效率速度，和减少项目体积大小,,
			        meta: {
						icon:'el-icon-user',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
			            menuShow: true,
			            menuName: '用户',
						jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
			        }
			    },
				
				
			]
			
		},
		//用户页end
		
		
		
		//订单页start
		
		{
		    path: '/',
		    name: 'layout',
		    component: Layout,//代表当路由为 /子路由index 时同时显示 /子路由index 路由对应的子路由的页面内容和 / 对应的当前属性component指定的Layout组件对应的页面布局
		    meta: {
				icon:'el-icon-tickets',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
		        menuShow: true,// 是否在侧边导航栏中显示当前路由  在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				menuName:'订单', // 侧边导航栏中显示的导航栏项路由名称 并且名称为空就不会在面包屑上显示了    在其他xx.vue页面可以使用 $router.options.routes代表返回路由配置的路由数组  路由数组中的元素item.meta.menuShow代表获取在路由项配置中属性名meta对象中指定属性的值 
				jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
			},
			
			children: [//子路由格式
			    {
			        path: '/orderList',//代表匹配路由地址为 /index
			        name: 'orderList',
			        component: () =>import("../views/orderList/orderList.vue"),//懒加载组件，可以提高效率速度，和减少项目体积大小,,
			        meta: {
						icon:'el-icon-tickets',//侧边导航栏中显示的导航栏项路由图标,值为elementui图标的class值
			            menuShow: true,
			            menuName: '订单',
						jueSheList:['admin'],//允许访问当前配置的路由地址的角色权限标识名称数组
			        }
			    },
				
				
			]
			
		},
		//订单页end
		
		

		
		
		
		//404页start
	  {
		path: '*',
		name: 'my404',
		meta: {
		  jueSheList:['*']
		},
		component: () => import('../views/my404.vue')
	  }
	  //404页end
		
		

];










//创建router路由对象
const router = new VueRouter({
  routes
});


//访问权限控制的方式一  角色标识名称方式：(让前端校验用户可访问的路由权限并把路由配置信息写在前端并让后台返回当前登录的用户的角色标识名称) start
//  坏处是增加角色和角色权限和菜单列表都需要改代码改数据库

//页面路由跳转前的处理方法简称路由守卫,to为要跳转到的路由地址配置项对象,from为当前页面的路由地址配置项对象,next是一个方法代表是否放行或重定向到哪个路由页面
router.beforeEach((to, from, next) => {
	//测试放行代码start
	//next();
	//return
	//测试放行代码end
	
	
	
	
	
	
	
	
	//console.log('tototo===',to)
	// 每次切换页面时，调用进度条
	window.$NProgress.start();
	
  //iView.LoadingBar.start()//在页面顶部显示一个加载中进度条
  const token = localStorage.getItem("token");//获取当前用户token
  const role = localStorage.getItem("role")?localStorage.getItem("role").replace(/3alxi2fdksjfkljwijljsk128ldfln324kodnvjdsl32432jfiwefjls32432idaxpoife983iw2/g,''):'';//获取当前用户token
  
  
  if (!token && to.name !== 'login') {//如果没有登录并且要跳转到页面不是login登录页那么就跳转到登录页,  to.name代表返回要跳转到的路由地址配置项对象中属性名name的值(上面配置的路由对象中属性名name的值)
    //next({
      //name: 'login' 
    //})//代表把当前路由重定向到上面配置路由地址配置对象中指定属性名name的值为login所在路由配置对象中属性path指定的路由地址上
	next({ path: '/login' })//代表把当前路由重定向到指定的路由地址上
  } else {//如果已登录并且要跳转到页面不是login登录页那么就进行权限校验,有权限则跳转,没有权限则重定向到404页面
	  console.log('11111')
    if (role) {//获取当前用户的角色标识名称,如果有数据那么就查找要跳转到的路由配置项对象中属性jueSheList角色权限标识名称数组中是否有当前用户所拥有的角色标识名称如果有则代表有权限访问那么就进行跳转,没有则代表没有权限访问那么就跳转到404
      
	  console.log('to=>',to)
	  if( to.meta.jueSheList.indexOf(role)==-1){//如果要跳转到的路由配置项对象中属性meta指定的对象中属性jueSheList角色权限标识名称数组中没有当前用户所拥有的角色标识名称那么就跳转到404
		   //next({
				//name: 'my404' 
			//})//代表把当前路由重定向到上面配置路由地址配置对象中指定属性名name的值为404所在路由配置对象中属性path指定的路由地址上
			console.log('没有匹配到');
			if(to.path=='/my404' || to.path=='/login'){//如果当前访问的是404页面那么就放行,否则就跳转到404页面
				next();
			}else{
				next({ path: '/my404' })//代表把当前路由重定向到指定的路由地址上
			}
			
			
			
	  }else{////如果要跳转到的路由配置项对象中属性meta指定的对象中属性jueSheList角色权限标识名称数组中有当前用户所拥有的角色标识名称那么就放行进行跳转
		  next() // 代表放行当前要跳转到的路由地址进行跳转页面
	  }
	  
	  
	  
    } else {//如果没有当前用户的角色标识名称那么就跳到登录页
	
		if(to.path=='/login'){//如果当前跳转到的是login登录页就放行
			next();
		}else{
			next({ path: '/login' })//代表把当前路由重定向到指定的路由地址上
		}
		
	  
    }
  }
})
//页面路由跳转后的处理方法简称路由守卫
router.afterEach(to => {
	// 在即将进入新的页面组件前，关闭掉进度条
	window.$NProgress.done()
	window.scrollTo(0, 0)//把滚动条滚动到顶部
})
//访问权限控制的方式一 end

//访问权限控制的方式二 动态路由方式： (让后端校验用户可访问的路由权限并把路由配置信息写在后端并让后端返回路由配置信息给前端然后前端再动态添加路由配置信息) 直接请求接口获取当前用户能访问的路由配置信息再动态添加路由(要解决当用户手动刷新页面导致store全局对象数据和动态添加的路由配置信息回复初始化的问题)
// 好处是增加角色和角色权限和菜单列表都可以在页面上操作

export default router;
