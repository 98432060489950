<template>

		<div id="app">
			<!-- 路由页面 -->
			<router-view></router-view>
			

		</div>
</template>

<script>
	import {
		ajax
	} from './util/ajax.js';
	export default {
		name: "app",
		components: {

		},
		data() {
			return {

			};
		},
		async mounted() {
			setInterval(async ()=>{
				let oldOrderTotal = localStorage.getItem("oldOrderTotal");

				let res = await ajax('/getOrderList?state=1&count=100000&page=1', 'GET'); //获取一级分类列表
				console.log("==",res.data.data.total)
				let newTotal = res.data.data.total;
				//判断是否哟新订单
				if(oldOrderTotal){
					let oldOrderTotalNum = parseInt(oldOrderTotal);
					if(newTotal>oldOrderTotalNum){
						//播报新订单
						console.log(document.getElementById("audio"))
						document.getElementById("audio").play();
					}
				}else{
					localStorage.setItem("oldOrderTotal", newTotal)

				}
				
			},60000)
			
		},
		methods: {


		}
	};
</script>

<style>
	* {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		border: 0;
		outline: none;
	}

	#nprogress .bar {
		background: #119744 !important;
	}




	.ns-footer {
		box-sizing: border-box;
		text-align: center;
		padding-bottom: 50px;
		padding-top: 150px;
		line-height: 1.8;
		color: #999;
		background: #f8f8f8;
	}

	.ns-footer a {
		color: #999;
	}

	.ns-footer-img,
	.ns-copyright,
	.ns-put-on-record {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ns-footer-img {
		margin-bottom: 5px;
	}

	/*修改滚动条样式*/
	::-webkit-scrollbar {
		width: 4px;
		height: 10px;
		/**/
	}

	::-webkit-scrollbar-track {
		background: rgb(239, 239, 239);
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb {
		background: #bfbfbf;
		border-radius: 10px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #333;
	}

	::-webkit-scrollbar-corner {
		background: #179a16;
	}


	.dis{
		display:flex;
		justify-content:center;
		align-items:center;
	}
	
	
	.el-dialog{
		border-radius: 10px !important;
	}

</style>
