import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./plugins/element.js";





import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
window.ElementUI = ElementUI;


//主题配置 主题色 #119744  rgb(17,151,68,0.8)
import './theme/index.css';

import NProgress from 'nprogress'
window.$NProgress = NProgress
import 'nprogress/nprogress.css'

NProgress.configure({
	easing: 'ease', // 动画方式    
	speed: 500, // 递增进度条的速度    
	showSpinner: false, // 是否显示加载ico    
	trickleSpeed: 200, // 自动递增间隔    
	minimum: 0.3 // 初始化时的最小百分比
})

import axios from 'axios'

window.$axios = axios;
Vue.prototype.$axios = axios //全局注册，使用方法为:this.$axios
Vue.config.productionTip = false;




//按需引入echarts start
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core';
Vue.prototype.$echarts = echarts
// 引入柱状图和折线图，图表后缀都为 Chart
import {
	BarChart,
	LineChart
} from 'echarts/charts';


// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
	TitleComponent,
	TooltipComponent,
	GridComponent,
	DatasetComponent,
	DatasetComponentOption,
	TransformComponent
} from 'echarts/components';
// 标签自动布局，全局过渡动画等特性
import {
	LabelLayout,
	UniversalTransition
} from 'echarts/features';
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import {
	CanvasRenderer
} from 'echarts/renderers';

// 注册必须的组件
echarts.use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	DatasetComponent,
	TransformComponent,
	BarChart,
	LineChart,
	LabelLayout,
	UniversalTransition,
	CanvasRenderer
]);

//按需引入echarts end



//vue-quill-editor富文本编辑器上的全屏按钮start

const domList = [{
		dom: `<svg  t="1637824425355" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10463"><path d="M243.2 780.8v-179.2H153.6v179.2c0 49.28 40.32 89.6 89.6 89.6h179.2v-89.6H243.2zM780.8 153.6h-179.2v89.6h179.2v179.2h89.6V243.2c0-49.28-40.32-89.6-89.6-89.6zM243.2 243.2h179.2V153.6H243.2c-49.28 0-89.6 40.32-89.6 89.6v179.2h89.6V243.2z m537.6 537.6h-179.2v89.6h179.2c49.28 0 89.6-40.32 89.6-89.6v-179.2h-89.6v179.2z" p-id="10464" fill="#000000"></path></svg>`,
		tit: "最大化",
		id: "maxId",
		display: "block",
	},
	{
		dom: `<svg t="1637824296192" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6336"><path d="M341.065143 910.189714v-146.285714c0-53.686857-43.885714-97.572571-97.572572-97.572571h-146.285714a48.786286 48.786286 0 0 0 0 97.499428h146.285714v146.285714a48.786286 48.786286 0 1 0 97.499429 0z m-292.571429-617.910857c0 26.916571 21.796571 48.786286 48.713143 48.786286h146.285714c53.686857 0 97.572571-43.885714 97.572572-97.572572v-146.285714a48.786286 48.786286 0 0 0-97.499429 0v146.285714h-146.285714a48.786286 48.786286 0 0 0-48.786286 48.786286z m910.409143 0a48.786286 48.786286 0 0 0-48.713143-48.786286h-146.285714v-146.285714a48.786286 48.786286 0 1 0-97.499429 0v146.285714c0 53.686857 43.885714 97.572571 97.499429 97.572572h146.285714a48.786286 48.786286 0 0 0 48.713143-48.786286z m0 422.765714a48.786286 48.786286 0 0 0-48.713143-48.713142h-146.285714c-53.686857 0-97.572571 43.885714-97.572571 97.572571v146.285714a48.786286 48.786286 0 1 0 97.499428 0v-146.285714h146.285714a48.786286 48.786286 0 0 0 48.786286-48.786286z" fill="#000000" p-id="6337"></path></svg>`,
		tit: "还原",
		id: "minId",
		display: "none",
	},
];

/**@ bing
 *2021-11-25 15:58:21
 * v-maxWindow: 只针对 vue-quill-editor组件  最大化最小化
 */
Vue.directive("maxWindow", {
	//在 <quill-editor>标签添加属性 v-maxWindow 就可以实现vue-quill-editor编辑器上的工具栏上出现全屏按钮
	bind(el, binding, vnode, oldVnode) {
		setTimeout(() => {


			let dialogHeaderEl = el.querySelector(".ql-toolbar");

			domList.map((item) => {
				let dom = document.createElement("span");

				dom.className = "ql-formats";

				dom.innerHTML = `<button title="${item.tit}" style="display:${item.display}" id="${item.id}"  type="button" class="ql-clean">${item.dom}</button>`;

				dialogHeaderEl.appendChild(dom);
			});

			//最大化
			document.getElementById("maxId").onclick = () => {
				console.log('最大化')

				el.style.width = 100 + "vw";
				el.style.height = 100 + "vh";
				el.style.position = "fixed";
				el.style.top = 0;
				el.style.left = 0;
				el.style.zIndex = 9999;
				el.style.background = "white";

				document.getElementById("maxId").style.display = "none";
				document.getElementById("minId").style.display = "block";
			};
			//最小化
			document.getElementById("minId").onclick = () => {
				console.log('最小化')

				el.style.width = "auto";
				el.style.height = "auto";
				el.style.position = "inherit";

				document.getElementById("maxId").style.display = "block";
				document.getElementById("minId").style.display = "none";
			};
		}, 1000);
	},
});
//vue-quill-editor富文本编辑器上的全屏按钮end






new Vue({
	router,
	store,
	render: h => h(App)
}).$mount("#app");
