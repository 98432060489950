<template>
  <div class="dis" style="margin-top:200px;flex-direction: column;">
	<img src="../assets/404.png"/>
	<div @click="go" style="cursor: pointer; margin-top: 30px;color: #666;text-align: center;">回到登录页</div>
  </div>
</template>

<script>
  export default {
    name: 'my404',
    components: {},
    data() {
      return {
		
      }
    },
    mounted() {

    },
    methods: {
		go(){
			this.$router.push('/login')
		},
    }
  }
</script>

<style scoped>



</style>
